/* eslint-disable prefer-promise-reject-errors */
/* eslint-disable @typescript-eslint/no-explicit-any */
/// <reference types="chrome"/>
import { Nullable } from '../../customtypes.util'

const PINPAD_USER_KEY = 'userPinpadKey'

export class Pinpad {
  private port: Nullable<chrome.runtime.Port>
  private nameApp: string
  private env: string

  private readonly password: string
  private readonly username: string

  constructor (
    nameApp: string,
    env: string,
    username: string,
    password: string
  ) {
    this.nameApp = nameApp
    this.env = env

    this.password = password
    this.username = username

    try {
      this.port = chrome.runtime.connect(nameApp)
    } catch (err) {
      // ignore
    }
  }

  public init () {
    return new Promise((resolve, reject) => {
      if (typeof chrome === 'undefined') {
        return reject('Debes utilizar Chrome')
      }

      if (!this.port) {
        return reject('No tienes conexión con el servidor')
      }

      const nameApp = this.nameApp
      const ambiente = this.env
      const versionApp = 'Versión 1.0.15'
      console.log(versionApp)

      const portL = chrome.runtime.connect(nameApp, { name: 'launchApp' })
      portL.postMessage(nameApp)
      portL.onMessage.addListener((response) => {
        const objLogin = JSON.parse(response)

        if (objLogin.RESPUESTA !== 'ok') {
          return reject(
            console.log('No tienes conexión con el centro de pagos')
          )
        }

        let obj: { RESPUESTA: any; ERROR: any }
        let aux: string | null

        // objeto de usuario login
        const objDataLogin = {
          Ambiente: ambiente,
          Usuario: this.username,
          Pass: this.password
        }

        console.log('Login')
        let portL = chrome.runtime.connect(nameApp, { name: 'login' })
        portL.postMessage(objDataLogin)
        portL.onMessage.addListener((response) => {
          console.log('respWP-' + response)

          obj = JSON.parse(response)

          aux = obj.RESPUESTA

          if (aux == null || aux === 'error') {
            return reject(obj.ERROR)
          }

          this.SetItemUserData(response)
          this.SetItemUser(objDataLogin.Usuario + '|' + this.password)

          const objUser = this.GetItemUserData()

          // objeto de getKeys
          const objDataKeys = {
            Ambiente: ambiente,
            Usuario: objDataLogin.Usuario,
            Pass: objDataLogin.Pass,
            Country: objUser.bs_country,
            IdBranch: objUser.bs_branch,
            IdCompany: objUser.bs_company
          }

          // Se llaman las llaves RSA
          console.log('getKeyRSA')
          portL = chrome.runtime.connect(nameApp, { name: 'getKeysRSA' })
          portL.postMessage(objDataKeys)
          portL.onMessage.addListener((response) => {
            console.log('respWP' + response)
            this.SetItemPinpadData(response)

            obj = JSON.parse(response)
            aux = obj.RESPUESTA

            if (aux == null || aux === 'error') {
              return reject(obj.ERROR)
            } else {
              console.log('Login correcto...')
              return resolve(portL.onMessage)
            }
          })
        })
      })
    })
  }

  public readCard (amount: number, reference: string, whiteList: string[]) {
    return new Promise((resolve, reject) => {
      if (!reference) {
        return reject('La referencia no puede estar vacio')
      }

      // objeto de la venta
      const objDataVenta = {
        Ambiente: '',
        Country: '',
        IdBranch: '',
        IdCompany: '',
        pwd: '',
        User: '',
        UserTRX: '',
        EMV: '',
        ModeloTerminal: '',
        SerieTerminal: '',
        Contactless: '',
        Printer: '',
        VersionTerminal: '',
        TpOperation: String(11),
        Reference: String(reference),
        Amount: String(amount),
        Currency: 'MXN',
        // Merchant: '123456',
        Merchant: '',
        Reverse: ''
      }

      if (objDataVenta.TpOperation === '') {
        reject('El tipo de operación no puede ir vacía')
        return
      }

      const obj = this.GetItemPinpadData()
      const nameApp = this.nameApp
      const ambiente = this.env
      const objDataTRX = {
        Ambiente: ambiente,
        Currency: 'MXN',
        CurrencyCode: '0484',
        Amount: String(amount),

        // Seccion Pinpad
        TimeOutPinPad: '60',
        SoportaFirma: '',
        SoportaCTLS: obj.soportaCTLS,
        MarcaTerminal: obj.marca,
        ModeloTerminal: obj.modelo
      }

      let portL = chrome.runtime.connect(nameApp, { name: 'readCard' })
      portL.postMessage(objDataTRX)
      portL.onMessage.addListener((response) => {
        console.log('respWP-' + response)

        let aux = ''
        let objTRX = JSON.parse(response)

        aux = objTRX.RESPUESTA

        if (aux == null || aux === 'error') {
          reject(objTRX.codError + ': ' + objTRX.ERROR)
          return
        }

        // SE PROCESA EL RESULTADO DE LA TARJETA
        let binT = ''
        if (objTRX.maskPan.length > 6) binT = objTRX.maskPan.substring(0, 6)

        const obj = this.GetItemUserData()

        // objeto de Afiliaciones
        const objDataMerchant = {
          Ambiente: ambiente,
          BIN: binT,
          User: obj.bs_user,
          Currency: objDataTRX.Currency
        }

        objDataVenta.Currency = objDataTRX.Currency
        // Get Afiliaciones
        portL = chrome.runtime.connect(nameApp, { name: 'getMerchant' })
        portL.postMessage(objDataMerchant)
        portL.onMessage.addListener(function getResp (response) {
          console.log('respWP getMerchant-' + response)

          objTRX = JSON.parse(response)
          aux = objTRX.respuesta

          if (aux == null || aux === '0') {
            let respMer = objTRX.nb_respuesta

            if (respMer === '' || respMer == null || respMer === undefined) {
              respMer = objTRX.ERROR
            }

            reject('xx' + ': ' + respMer)
            return
          }
          // Numero de Afiliacion (Merchant)
          const nodo = objTRX.contado
          const nodosAf = nodo.af
          let merchantId: string | undefined

          if (Array.isArray(nodosAf)) {
            for (let i = 0; i < nodosAf.length; i++) {
              for (let j = 0; j < whiteList.length; j++) {
                if (nodosAf[i].merchantId !== whiteList[j]) continue

                merchantId = nodosAf[i].merchantId
                break
              }
              if (merchantId !== undefined) break
            }

            if (merchantId === undefined) merchantId = nodosAf[0].merchantId
          } else {
            merchantId = nodosAf.merchant
          }

          console.log(merchantId)
          objDataVenta.Merchant = merchantId ?? ''

          resolve(objDataVenta)
        })
      })
    })
  }

  public sendToken (objDataVenta: any): Promise<any> {
    return new Promise((resolve, reject) => {
      const obj = this.GetItemUserData()
      const obj2 = this.GetItemPinpadData()
      const params = this.GetItemUser()

      let objResponse: any
      let respTRX: any
      let respVoucher: any
      let respTkn: any

      const nameApp = this.nameApp
      const ambiente = this.env

      const objDataVentaTkn = {
        Ambiente: ambiente,
        Country: obj.bs_country,
        IdBranch: obj.bs_branch,
        IdCompany: obj.bs_company,
        pwd: params.pass,
        User: params.usuario,
        UserTRX: 'userPinpadWeb',
        EMV: obj2.EMV,
        ModeloTerminal: obj2.modelo,
        SerieTerminal: obj2.serie,
        Contactless: obj2.soportaCTLS,
        Printer: obj2.impresora,
        VersionTerminal: obj2.versionApp,
        TpOperation: objDataVenta.TpOperation,
        Reference: objDataVenta.Reference,
        Currency: objDataVenta.Currency,
        Merchant: objDataVenta.Merchant,
        Reverse: obj.ExecuteReverse,
        Amount: objDataVenta.Amount,
        GenerateToken: false
      }

      // Se llenan los valores del objeto de la transacción

      const portL = chrome.runtime.connect(nameApp, {
        name: 'sndVentaDirectaToken'
      })

      portL.postMessage(objDataVentaTkn)
      portL.onMessage.addListener(function getResp (response) {
        console.log('respWP-' + response)
        objResponse = JSON.parse(response)

        if (objResponse.response !== 'approved') {
          let codeError
          let msgError

          if (objResponse.response === 'denied') {
            codeError = objResponse.cd_response
            msgError = objResponse.friendly_response

            respTRX =
              'Response: ' +
              objResponse.response +
              '  /  No. Operación: ' +
              objResponse.foliocpagos +
              '  /  Resp Code: ' +
              objResponse.cd_response +
              '  /  Friendly Resp: ' +
              objResponse.friendly_response
            respVoucher = ''
            respTkn =
              'Token ErrorCode: ' +
              objResponse.tokenization.codeResponse +
              '  /  Token ErrorMessage: ' +
              objResponse.tokenization.errorMessage
          } else {
            if (objResponse.response === 'error') {
              codeError = objResponse.cd_error
              msgError = objResponse.nb_error

              respTRX =
                'Response: ' +
                objResponse.response +
                '  /  No. Operación: ' +
                objResponse.foliocpagos +
                '  /  CodeError: ' +
                objResponse.cd_error +
                '  /  DesError: ' +
                objResponse.nb_error
              respVoucher = ''
              respTkn =
                'Token ErrorCode: ' +
                objResponse.tokenization.codeResponse +
                '  /  Token ErrorMessage: ' +
                objResponse.tokenization.errorMessage
            } else {
              codeError = objResponse.codError
              msgError = objResponse.ERROR

              respTRX =
                'Response: ' +
                objResponse.RESPUESTA +
                '  /  Error: ' +
                objResponse.ERROR +
                '  /  CodeError: ' +
                objResponse.codError +
                '  /  DesError: ' +
                objResponse.ERROR
              respVoucher = ''
            }
          }
          reject(respTRX)
          return
        }

        respTRX =
          'Response: ' +
          objResponse.response +
          '  /  No. Operación: ' +
          objResponse.foliocpagos +
          '  /  No. Auth: ' +
          objResponse.auth +
          '  /  CdResponse: ' +
          objResponse.cd_response +
          '  /  Arqc: ' +
          objResponse.arqc +
          '  /  Aid: ' +
          objResponse.appid +
          '  /  AidLabel: ' +
          objResponse.appidlabel

        if (objResponse.tokenization.tknresponse === 'success') {
          respTkn =
            'Token Result: ' +
            objResponse.tokenization.tknresponse +
            '  /  Code Response : ' +
            objResponse.tokenization.codeResponse +
            '  /  Token : ' +
            objResponse.tokenization.token
        } else {
          respTkn =
            'Token Result: ' +
            objResponse.tokenization.tknresponse +
            ' /  Code Response : ' +
            objResponse.tokenization.codeResponse +
            '  /  Message : ' +
            objResponse.tokenization.errorMessage
        }

        respVoucher = 'VoucherComercio: '
        respVoucher = respVoucher + '\n' + objResponse.voucher_comercio

        respVoucher = respVoucher + 'VoucherCliente'
        respVoucher = respVoucher + '\n' + objResponse.voucher_cliente

        resolve(objResponse)
      })
    })
  }

  public printVoucher (objResponse: any) {
    return new Promise((resolve, reject) => {
      const obj = this.GetItemPinpadData()
      const nameApp = this.nameApp
      const ambiente = this.env

      if (obj.impresora === '1') {
        // objeto para impresion
        const objPrint = {
          Ambiente: ambiente,
          VoucherComercio: objResponse.voucher_comercio,
          VoucherCliente: objResponse.voucher_cliente,
          ModeloTerminal: obj.modelo
        }

        const portL = chrome.runtime.connect(nameApp, { name: 'printVoucher' })
        portL.postMessage(objPrint)
        portL.onMessage.addListener(function getResp (response) {
          console.log('respWP-' + response)
        })
      }

      resolve(objResponse)
    })
  }

  public verifyVoucher (voucher: any) {
    voucher = voucher.replace('@cnb logo_cpagos', '')
    voucher = voucher.replace('@cnn ver_app', '')
    voucher = voucher.replace(/@cnb /g, '')
    voucher = voucher.replace(/@cnn /g, '')
    voucher = voucher.replace(/@br /g, '')
    voucher = voucher.replace(/@lnn /g, '')
    voucher = voucher.replace(/@lsn /g, '')
    voucher = voucher.replace(/@bc /g, '')

    return voucher
  }

  public cancelRead () {
    return new Promise((resolve, reject) => {
      const nameApp = this.nameApp
      const obj = this.GetItemPinpadData()

      const portL = chrome.runtime.connect(nameApp, { name: 'cancelReadCard' })
      portL.postMessage(obj.marca)

      portL.onMessage.addListener(function getResp (response) {
        const objTRX = JSON.parse(response)

        return resolve(console.log(objTRX.codError + ': ' + objTRX.ERROR))
      })
    })
  }

  public cancel (operationNumber: string, auth: string, amount: number) {
    return new Promise((resolve, reject) => {
      const params = this.GetItemUser()
      let obj = this.GetItemUserData()

      const objDataCancelacion = {
        Ambiente: this.env,
        User: params.usuario,
        Pwd: params.pass,
        IdBranch: obj.bs_branch,
        IdCompany: obj.bs_company,
        Country: obj.bs_country,
        UserTRX: 'userPinpadWeb',
        Tx_OperationNumber: operationNumber,
        Tx_Auth: auth,
        Amount: `${amount.toFixed(2)}`
      }

      const nameApp = this.nameApp
      let temp2

      obj = this.GetItemPinpadData()

      // objeto para impresion
      const objPrint = {
        Ambiente: this.env,
        VoucherComercio: '',
        VoucherCliente: '',
        ModeloTerminal: obj.modelo
      }

      const portL = chrome.runtime.connect(nameApp, { name: 'sndCancelacion' })
      portL.postMessage(objDataCancelacion)
      portL.onMessage.addListener((response) => {
        let resp: string
        const objTRX = JSON.parse(response)

        if (objTRX.response !== 'approved') {
          return reject(objTRX.cd_error + ': ' + objTRX.nb_error)
        }

        resp = 'Response: ' + objTRX.response
        resp += '\n' + 'NOperacion: ' + objTRX.foliocpagos
        resp += '\n' + 'NAutorizacion: ' + objTRX.auth
        resp += '\n' + 'Refencia: ' + objTRX.reference
        console.log(resp)

        const promise2 = this.getVoucher(nameApp, 'getRspVoucherCliente')
        // se obtienen los vouchers
        promise2
          .then(function (successMessage: any) {
            // voucher cliente
            temp2 = JSON.parse(successMessage)
            objPrint.VoucherCliente = temp2.voucher_cliente
          })
          .then(function () {
            return resolve(objPrint)
          })
      })
    })
  }

  public getVoucher (nameApp: string, tipoOperacion: string) {
    return new Promise(function (resolve, reject) {
      const portL = chrome.runtime.connect(nameApp, { name: tipoOperacion })
      portL.postMessage('')
      portL.onMessage.addListener(function getResp (response) {
        resolve(response)
      })
    })
  }

  private SetItemUserData (data: any) {
    localStorage.setItem('datosusuario', data)
  }

  private SetItemUser (user: any) {
    localStorage.setItem(PINPAD_USER_KEY, user)
  }

  private SetItemPinpadData (data: any) {
    localStorage.setItem('datosPinPad', data)
  }

  private GetItemUserData () {
    const userData = localStorage.getItem('datosusuario') ?? 'null'
    return JSON.parse(userData)
  }

  private GetItemPinpadData () {
    const userData = localStorage.getItem('datosPinPad') ?? 'null'
    return JSON.parse(userData)
  }

  private GetItemUser () {
    const datosUser = localStorage.getItem(PINPAD_USER_KEY)
    const params = datosUser?.split('|')
    if (!params) {
      return { usuario: null, pass: null }
    }

    const objeto = { usuario: params[0], pass: params[1] }

    return objeto
  }
}
